import SentimentEndeksler from "./SentimentEndeksler";
const ImkbEndeksSenetler = [
    { val: [], name: "A1CAP", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XAKUR;" },
    { val: [], name: "ACSEL", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XKTUM,XKTMT,XUSIN,XSINS,XKMYA,XSDNZ;" },
    { val: [], name: "ADEL", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSIN,XSINS,XSIST;" },
    { val: [], name: "ADESE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUHIZ,XSKON;" },
    { val: [], name: "ADGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUGRA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "AEFES", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XTM25S,XUSIN,XSINS,XGIDA,XSIST;" },
    { val: [], name: "AFYON", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XSINS,XTAST;" },
    { val: [], name: "AGESA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSRD,XUGRA,XUMAL,XSGRT;" },
    { val: [], name: "AGHOL", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XKURY,XUSRD,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "AGROT", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XUSIN,XSINS;" },
    { val: [], name: "AGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "AHGAZ", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSIST;" },
    { val: [], name: "AHSGY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "AKBNK", ix: "XU100,XU050,XU030,XLBNK,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XTM25S,XUMAL,XBANK;" },
    { val: [], name: "AKCNS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XSRDK,XKTMT,XUSRD,XUSIN,XSINS,XTAST,XSIST;" },
    { val: [], name: "AKENR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUHIZ,XELKT,XSIST;" },
    { val: [], name: "AKFGY", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "AKFYE", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUHIZ,XELKT,XSANK;" },
    { val: [], name: "AKGRT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSRD,XUMAL,XSGRT;" },
    { val: [], name: "AKMGY", ix: "XUTUM,XTUMY,XTMTU,XKURY,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "AKSA", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XSRDK,XKTMT,XKURY,XUSRD,XTM25S,XUSIN,XSINS,XKMYA;" },
    { val: [], name: "AKSEN", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XSRDK,XKTMT,XKURY,XUSRD,XTM25S,XUHIZ,XELKT,XSIST;" },
    { val: [], name: "AKSGY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "AKSUE", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XELKT;" },
    { val: [], name: "AKYHO", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "ALARK", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XKURY,XUGRA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "ALBRK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XSRDK,XKURY,XUSRD,XUGRA,XUMAL,XBANK;" },
    { val: [], name: "ALCAR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSIN,XSINS,XMESY,XSIST;" },
    { val: [], name: "ALCTL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUTEK,XTKJS,XBLSM,XSANK;" },
    { val: [], name: "ALFAS", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XUGRA,XUHIZ,XELKT;" },
    { val: [], name: "ALGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "ALKA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XKAGT,XSIZM;" },
    { val: [], name: "ALKIM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XKMYA;" },
    { val: [], name: "ALKLC", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "ALMAD", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XSINS,XMADN,XSTKR;" },
    { val: [], name: "ALTNY", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XUTEK,XTKJS,XSIST;" },
    { val: [], name: "ALVES", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XSINS,XMESY,XSANK;" },
    { val: [], name: "ANELE", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XINSA,XSIST;" },
    { val: [], name: "ANGEN", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XSINS,XKMYA,XSIST;" },
    { val: [], name: "ANHYT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUSRD,XUMAL,XSGRT;" },
    { val: [], name: "ANSGR", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XSGRT;" },
    { val: [], name: "ARASE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUHIZ,XELKT;" },
    { val: [], name: "ARCLK", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XSD25,XUSIN,XSINS,XMESY,XSIST;" },
    { val: [], name: "ARDYZ", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XKTUM,XK100,XK050,XKTMT,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "ARENA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "ARSAN", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XTEKS;" },
    { val: [], name: "ARTMS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XSINS,XTEKS;" },
    { val: [], name: "ARZUM", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKURY,XUHIZ,XTCRT,XSIST;" },
    { val: [], name: "ASELS", ix: "XU100,XU050,XU030,X10XB,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XTMTU,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XUTEK,XTKJS,XSANK;" },
    { val: [], name: "ASGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "ASTOR", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XHARZ,XUSIN,XSINS,XMESY,XSANK;" },
    { val: [], name: "ASUZU", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XK050,XKTMT,XKURY,XUSIN,XSINS,XMESY,XSKOC;" },
    { val: [], name: "ATAGY", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "ATAKP", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "ATATP", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "ATEKS", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XTEKS;" },
    { val: [], name: "ATLAS", ix: "XYORT;" },
    { val: [], name: "AVGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "AVHOL", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "AVOD", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XGIDA,XSIZM;" },
    { val: [], name: "AVPGY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUGRA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "AVTUR", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSIST;" },
    { val: [], name: "AYCES", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSIZM;" },
    { val: [], name: "AYDEM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUHIZ,XELKT,XSDNZ;" },
    { val: [], name: "AYEN", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUHIZ,XELKT,XSKAY;" },
    { val: [], name: "AYES", ix: "XUTUM,XTUMY;" },
    { val: [], name: "AYGAZ", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKURY,XUSRD,XUSIN,XSINS,XKMYA,XSIST;" },
    { val: [], name: "AZTEK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "BAGFS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSIN,XSINS,XKMYA,XSBAL;" },
    { val: [], name: "BAHKM", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUSIN,XSINS,XKMYA;" },
    { val: [], name: "BAKAB", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XKAGT,XSIZM;" },
    { val: [], name: "BALAT", ix: "XUTUM,XTUMY;" },
    { val: [], name: "BANVT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XGIDA,XSBAL;" },
    { val: [], name: "BARMA", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XKAGT,XSIZM;" },
    { val: [], name: "BASCM", ix: "XUTUM,XTUMY;" },
    { val: [], name: "BASGZ", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "BAYRK", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XKMYA,XSIST;" },
    { val: [], name: "BEGYO", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "BERA", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUMAL,XHOLD,XSKON;" },
    { val: [], name: "BEYAZ", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUHIZ,XULAS,XSBAL;" },
    { val: [], name: "BFREN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XSINS,XMESY,XSBUR;" },
    { val: [], name: "BIENY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XSINS,XTAST;" },
    { val: [], name: "BIGCH", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XTRZM,XSIST;" },
    { val: [], name: "BIMAS", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XUSRD,XSD25,XTM25S,XUHIZ,XTCRT;" },
    { val: [], name: "BINHO", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "BIOEN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XKURY,XUHIZ,XELKT,XSIZM;" },
    { val: [], name: "BIZIM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSRD,XUHIZ,XTCRT;" },
    { val: [], name: "BJKAS", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUHIZ,XSPOR,XSIST;" },
    { val: [], name: "BLCYT", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XTEKS,XSADA;" },
    { val: [], name: "BMSCH", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XMANA,XSMNS;" },
    { val: [], name: "BMSTL", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMANA,XSKOC;" },
    { val: [], name: "BNTAS", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMESY,XSBAL;" },
    { val: [], name: "BOBET", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XSINS,XTAST,XSIST;" },
    { val: [], name: "BORLS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XSIST;" },
    { val: [], name: "BORSK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "BOSSA", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XTEKS,XSADA;" },
    { val: [], name: "BRISA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUSRD,XUSIN,XSINS,XKMYA,XSKOC;" },
    { val: [], name: "BRKSN", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUSIN,XSINS,XKMYA,XSTKR;" },
    { val: [], name: "BRKVY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL;" },
    { val: [], name: "BRLSM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUHIZ,XINSA,XSIST;" },
    { val: [], name: "BRSAN", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XUSIN,XSINS,XMANA,XSIST;" },
    { val: [], name: "BRYAT", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XTM25S,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "BSOKE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSIN,XSINS,XTAST,XSAYD;" },
    { val: [], name: "BTCIM", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSIN,XSINS,XTAST,XSIZM;" },
    { val: [], name: "BUCIM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XSINS,XTAST,XSBUR;" },
    { val: [], name: "BURCE", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMANA,XSBUR;" },
    { val: [], name: "BURVA", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XMANA,XSBUR;" },
    { val: [], name: "BVSAN", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XUSIN,XSINS,XMESY,XSANK;" },
    { val: [], name: "BYDNR", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XUHIZ,XTRZM,XSIZM;" },
    { val: [], name: "CANTE", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUHIZ,XELKT;" },
    { val: [], name: "CATES", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XELKT;" },
    { val: [], name: "CCOLA", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XTM25S,XUSIN,XSINS,XGIDA,XSIST;" },
    { val: [], name: "CELHA", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XMANA,XSKOC;" },
    { val: [], name: "CEMAS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XK050,XUSIN,XSINS,XMANA;" },
    { val: [], name: "CEMTS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XSINS,XMANA,XSBUR;" },
    { val: [], name: "CEMZY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XSINS,XGIDA,XSBAL;" },
    { val: [], name: "CEOEM", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XSIST;" },
    { val: [], name: "CIMSA", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XUSRD,XSD25,XTM25S,XUSIN,XSINS,XTAST;" },
    { val: [], name: "CLEBI", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XKTUM,XK100,XK050,XKTMT,XUHIZ,XULAS,XSIST;" },
    { val: [], name: "CMBTN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XSINS,XTAST,XSIZM;" },
    { val: [], name: "CMENT", ix: "XUTUM,XTUMY;" },
    { val: [], name: "CONSE", ix: "XU500,XUTUM,XTUMY,XBANA,XKURY,XUHIZ,XELKT,XSIST;" },
    { val: [], name: "COSMO", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "CRDFA", ix: "XU500,XUTUM,XTUMY,XBANA,XKURY,XUMAL,XFINK;" },
    { val: [], name: "CRFSA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUHIZ,XTCRT;" },
    { val: [], name: "CUSAN", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUGRA,XUSIN,XSINS,XMANA,XSIST;" },
    { val: [], name: "CVKMD", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XSINS,XMADN,XSBUR;" },
    { val: [], name: "CWENE", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XHARZ,XKTUM,XK100,XK050,XK030,XUHIZ,XELKT,XSANT;" },
    { val: [], name: "DAGHL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "DAGI", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XTEKS,XSIST;" },
    { val: [], name: "DAPGM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUHIZ,XINSA,XSIST;" },
    { val: [], name: "DARDL", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "DCTTR", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUHIZ,XTCRT,XSIST;" },
    { val: [], name: "DENGE", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "DERHL", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "DERIM", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XTEKS,XSIST;" },
    { val: [], name: "DESA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XTEKS;" },
    { val: [], name: "DESPC", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "DEVA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XSINS,XKMYA,XSTKR;" },
    { val: [], name: "DGATE", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "DGGYO", ix: "XUTUM,XTUMY,XKTUM,XKURY,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "DGNMO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUGRA,XUSIN,XSINS,XKAGT;" },
    { val: [], name: "DITAS", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMESY;" },
    { val: [], name: "DMRGD", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XUGRA,XUSIN,XSINS,XGIDA,XSIST;" },
    { val: [], name: "DMSAS", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XMANA;" },
    { val: [], name: "DNISI", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XSINS,XKMYA,XSIZM;" },
    { val: [], name: "DOAS", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XSD25,XTM25S,XUHIZ,XTCRT,XSIST;" },
    { val: [], name: "DOBUR", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XSIST;" },
    { val: [], name: "DOCO", ix: "XU500,XUTUM,XTUMY,XBANA,XUSRD,XUHIZ,XTRZM;" },
    { val: [], name: "DOFER", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSINS,XMANA;" },
    { val: [], name: "DOGUB", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XTAST;" },
    { val: [], name: "DOHOL", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XKURY,XUSRD,XSD25,XUGRA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "DOKTA", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XMANA,XSBUR;" },
    { val: [], name: "DURDO", ix: "XUTUM,XTUMY,XBANA,XUSIN,XSINS,XKAGT,XSIST;" },
    { val: [], name: "DURKN", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSINS,XGIDA,XSANK;" },
    { val: [], name: "DYOBY", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XKMYA,XSKOC;" },
    { val: [], name: "DZGYO", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "EBEBK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XTCRT;" },
    { val: [], name: "ECILC", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XTM25S,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "ECZYT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "EDATA", ix: "XU500,XUTUM,XTUMY,XBANA,XUTEK,XTKJS,XBLSM,XSANK;" },
    { val: [], name: "EDIP", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSIST;" },
    { val: [], name: "EFORC", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "EGEEN", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XUSIN,XSINS,XMESY,XSIZM;" },
    { val: [], name: "EGEPO", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XSIZM;" },
    { val: [], name: "EGGUB", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUSIN,XSINS,XKMYA,XSIZM;" },
    { val: [], name: "EGPRO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUSIN,XSINS,XKMYA,XSIZM;" },
    { val: [], name: "EGSER", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XTAST,XSIZM;" },
    { val: [], name: "EKGYO", ix: "XU100,XU050,XU030,X10XB,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "EKOS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XSINS,XMESY,XSBAL;" },
    { val: [], name: "EKSUN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XSINS,XGIDA,XSTKR;" },
    { val: [], name: "ELITE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XSINS,XGIDA,XSANK;" },
    { val: [], name: "EMKEL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKOBI,XUSIN,XSINS,XMESY,XSANK;" },
    { val: [], name: "ENERY", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XUHIZ,XELKT,XSIST;" },
    { val: [], name: "ENJSA", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XSD25,XTM25S,XUHIZ,XELKT,XSIST;" },
    { val: [], name: "ENKAI", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XTM25S,XUHIZ,XINSA,XSIST;" },
    { val: [], name: "ENSRI", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKOBI,XUSIN,XSINS,XTEKS,XSTKR;" },
    { val: [], name: "ENTRA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSANK;" },
    { val: [], name: "EPLAS", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XKMYA,XSIZM;" },
    { val: [], name: "ERBOS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSIN,XSINS,XMANA,XSKAY;" },
    { val: [], name: "ERCB", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XSINS,XMANA;" },
    { val: [], name: "EREGL", ix: "XU100,XU050,XU030,X10XB,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XSD25,XUGRA,XUSIN,XSINS,XMANA;" },
    { val: [], name: "ERSU", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XGIDA,XSKON;" },
    { val: [], name: "ESCAR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUHIZ,XSIST;" },
    { val: [], name: "ESCOM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "ESEN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSRD,XUGRA,XUHIZ,XELKT,XSANK;" },
    { val: [], name: "ETILR", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSIST;" },
    { val: [], name: "ETYAT", ix: "XYORT;" },
    { val: [], name: "EUHOL", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "EUKYO", ix: "XYORT;" },
    { val: [], name: "EUPWR", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XHARZ,XKTUM,XK100,XK050,XK030,XUSIN,XSINS,XMESY,XSANK;" },
    { val: [], name: "EUREN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSIN,XSINS,XKMYA;" },
    { val: [], name: "EUYO", ix: "XYORT;" },
    { val: [], name: "EYGYO", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "FADE", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XGIDA,XSIZM;" },
    { val: [], name: "FENER", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUHIZ,XSPOR,XSIST;" },
    { val: [], name: "FLAP", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XUHIZ,XSIST;" },
    { val: [], name: "FMIZP", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XMESY,XSKOC;" },
    { val: [], name: "FONET", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSANK;" },
    { val: [], name: "FORMT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKOBI,XKTUM,XUSIN,XSINS,XMESY,XSKAY;" },
    { val: [], name: "FORTE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUTEK,XTKJS,XBLSM,XSANK;" },
    { val: [], name: "FRIGO", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUGRA,XUSIN,XSINS,XGIDA,XSBUR;" },
    { val: [], name: "FROTO", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XTM25S,XUSIN,XSINS,XMESY,XSKOC;" },
    { val: [], name: "FZLGY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "GARAN", ix: "XU100,XU050,XU030,XLBNK,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XTM25S,XUMAL,XBANK;" },
    { val: [], name: "GARFA", ix: "XU500,XUTUM,XTUMY,XBANA,XKURY,XUMAL,XFINK;" },
    { val: [], name: "GEDIK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUGRA,XUMAL,XAKUR;" },
    { val: [], name: "GEDZA", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XKMYA;" },
    { val: [], name: "GENIL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUGRA,XUHIZ,XTCRT,XSANK;" },
    { val: [], name: "GENTS", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUSIN,XSINS,XKAGT;" },
    { val: [], name: "GEREL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XSINS,XMESY;" },
    { val: [], name: "GESAN", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUHIZ,XINSA,XSANK;" },
    { val: [], name: "GIPTA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XSINS,XKAGT,XSANK;" },
    { val: [], name: "GLBMD", ix: "XUTUM,XTUMY,XBANA,XUMAL,XAKUR;" },
    { val: [], name: "GLCVY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUMAL;" },
    { val: [], name: "GLRYH", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "GLYHO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "GMTAS", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUGRA,XUHIZ,XTCRT;" },
    { val: [], name: "GOKNR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "GOLTS", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XSINS,XTAST;" },
    { val: [], name: "GOODY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSIN,XSINS,XKMYA,XSIST;" },
    { val: [], name: "GOZDE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD;" },
    { val: [], name: "GRNYO", ix: "XYORT;" },
    { val: [], name: "GRSEL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUHIZ,XULAS,XSIST;" },
    { val: [], name: "GRTHO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "GSDDE", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XULAS,XSIST;" },
    { val: [], name: "GSDHO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "GSRAY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUHIZ,XSPOR,XSIST;" },
    { val: [], name: "GUBRF", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUSIN,XSINS,XKMYA,XSIST;" },
    { val: [], name: "GUNDG", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSINS,XGIDA,XSMNS;" },
    { val: [], name: "GWIND", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKURY,XUSRD,XUHIZ,XELKT,XSBAL;" },
    { val: [], name: "GZNMI", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XSANT;" },
    { val: [], name: "HALKB", ix: "XU100,XU050,XLBNK,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XSD25,XUMAL,XBANK;" },
    { val: [], name: "HATEK", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XTEKS;" },
    { val: [], name: "HATSN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XSINS,XMESY;" },
    { val: [], name: "HDFGS", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD;" },
    { val: [], name: "HEDEF", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "HEKTS", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSIN,XSINS,XKMYA,XSKOC;" },
    { val: [], name: "HKTM", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMESY,XSKOC;" },
    { val: [], name: "HLGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKURY,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "HOROZ", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XULAS,XSIST;" },
    { val: [], name: "HRKET", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XULAS,XSIST;" },
    { val: [], name: "HTTBT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "HUBVC", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD;" },
    { val: [], name: "HUNER", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUHIZ,XELKT,XSIST;" },
    { val: [], name: "HURGZ", ix: "XU500,XUTUM,XTUMY,XBANA,XKURY,XUHIZ,XSIST;" },
    { val: [], name: "ICBCT", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XBANK;" },
    { val: [], name: "ICUGS", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD;" },
    { val: [], name: "IDGYO", ix: "XUTUM,XTUMY,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "IEYHO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "IHAAS", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XSIST;" },
    { val: [], name: "IHEVA", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XKURY,XUGRA,XUSIN,XSINS,XMESY,XSIST;" },
    { val: [], name: "IHGZT", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XSIST;" },
    { val: [], name: "IHLAS", ix: "XU500,XUTUM,XTUMY,XBANA,XKURY,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "IHLGM", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XSIZM;" },
    { val: [], name: "IHYAY", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "IMASM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XSINS,XMESY,XSKON;" },
    { val: [], name: "INDES", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "INFO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XAKUR;" },
    { val: [], name: "INGRM", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "INTEK", ix: "XUTUM,XTUMY;" },
    { val: [], name: "INTEM", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XTCRT,XSIST;" },
    { val: [], name: "INVEO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "INVES", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUGRA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "IPEKE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSIN,XSINS,XMADN,XSANK;" },
    { val: [], name: "ISATR", ix: "XUTUM,XTUMY,XTMTU,XUSRD,XUMAL,XBANK;" },
    { val: [], name: "ISBIR", ix: "XUTUM,XTUMY;" },
    { val: [], name: "ISBTR", ix: "XUTUM,XTUMY,XTMTU,XUSRD,XUMAL,XBANK;" },
    { val: [], name: "ISCTR", ix: "XU100,XU050,XU030,XLBNK,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XTM25S,XUMAL,XBANK;" },
    { val: [], name: "ISDMR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XSRDK,XKURY,XUSRD,XUSIN,XSINS,XMANA;" },
    { val: [], name: "ISFIN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUMAL,XFINK;" },
    { val: [], name: "ISGSY", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD;" },
    { val: [], name: "ISGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSRD,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "ISKPL", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUSIN,XSINS,XKMYA,XSKOC;" },
    { val: [], name: "ISMEN", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XUSRD,XTM25S,XUMAL,XAKUR;" },
    { val: [], name: "ISSEN", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUSIN,XSINS,XTEKS,XSBAL;" },
    { val: [], name: "ISYAT", ix: "XYORT;" },
    { val: [], name: "IZENR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSIZM;" },
    { val: [], name: "IZFAS", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XKMYA,XSIZM;" },
    { val: [], name: "IZINV", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XSIST;" },
    { val: [], name: "IZMDC", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUGRA,XUSIN,XSINS,XMANA,XSIZM;" },
    { val: [], name: "JANTS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XSINS,XMESY,XSAYD;" },
    { val: [], name: "KAPLM", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XKAGT,XSIZM;" },
    { val: [], name: "KAREL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUTEK,XTKJS,XBLSM,XSANK;" },
    { val: [], name: "KARSN", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XSINS,XMESY,XSBUR;" },
    { val: [], name: "KARTN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSIN,XSINS,XKAGT,XSKOC;" },
    { val: [], name: "KARYE", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XELKT,XSMNS;" },
    { val: [], name: "KATMR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XSINS,XMESY,XSIZM;" },
    { val: [], name: "KAYSE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUSIN,XSINS,XGIDA,XSKAY;" },
    { val: [], name: "KBORU", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XSINS,XKMYA;" },
    { val: [], name: "KCAER", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUSIN,XSINS,XMANA,XSIZM;" },
    { val: [], name: "KCHOL", ix: "XU100,XU050,XU030,X10XB,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XTM25S,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "KENT", ix: "XUTUM,XTUMY;" },
    { val: [], name: "KERVT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSRD,XUSIN,XSINS,XGIDA,XSBUR;" },
    { val: [], name: "KFEIN", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XKURY,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "KGYO", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "KIMMR", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XTCRT;" },
    { val: [], name: "KLGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "KLKIM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XKURY,XUSIN,XSINS,XTAST,XSIST;" },
    { val: [], name: "KLMSN", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XMESY,XSMNS;" },
    { val: [], name: "KLNMA", ix: "XUTUM,XTUMY;" },
    { val: [], name: "KLRHO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "KLSER", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XUSIN,XSINS,XTAST;" },
    { val: [], name: "KLSYN", ix: "XU500,XUTUM,XTUMY,XBANA,XUGRA,XUSIN,XSINS,XKAGT,XSTKR;" },
    { val: [], name: "KMPUR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKURY,XUSRD,XUSIN,XSINS,XKMYA,XSKOC;" },
    { val: [], name: "KNFRT", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XGIDA,XSDNZ;" },
    { val: [], name: "KOCMT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XSINS,XMANA;" },
    { val: [], name: "KONKA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUGRA,XUSIN,XSINS,XKAGT,XSKON;" },
    { val: [], name: "KONTR", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XTMTU,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XUHIZ,XSIST;" },
    { val: [], name: "KONYA", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XSINS,XTAST,XSKON;" },
    { val: [], name: "KOPOL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XSINS,XKMYA;" },
    { val: [], name: "KORDS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSRD,XUSIN,XSINS,XTEKS,XSKOC;" },
    { val: [], name: "KOTON", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XTCRT;" },
    { val: [], name: "KOZAA", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSIN,XSINS,XMADN,XSANK;" },
    { val: [], name: "KOZAL", ix: "XU100,XU050,XU030,X10XB,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUGRA,XUSIN,XSINS,XMADN,XSIZM;" },
    { val: [], name: "KRDMA", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XSRDK,XUSRD,XUGRA,XUSIN,XSINS,XMANA;" },
    { val: [], name: "KRDMB", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XSRDK,XUSRD,XUSIN,XSINS,XMANA;" },
    { val: [], name: "KRDMD", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XUGRA,XUSIN,XSINS,XMANA;" },
    { val: [], name: "KRGYO", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "KRONT", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "KRPLS", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XKMYA,XSIST;" },
    { val: [], name: "KRSTL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XGIDA,XSBAL;" },
    { val: [], name: "KRTEK", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XTEKS,XSKAY;" },
    { val: [], name: "KRVGD", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUGRA,XUSIN,XSINS,XGIDA,XSIST;" },
    { val: [], name: "KSTUR", ix: "XUTUM,XTUMY;" },
    { val: [], name: "KTLEV", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XHARZ,XKTUM,XK100,XK050,XK030,XUMAL;" },
    { val: [], name: "KTSKR", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "KUTPO", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XTAST;" },
    { val: [], name: "KUYAS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUGRA,XUHIZ,XINSA,XSIST;" },
    { val: [], name: "KZBGY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "KZGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "LIDER", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUGRA,XUHIZ,XSIST;" },
    { val: [], name: "LIDFA", ix: "XU500,XUTUM,XTUMY,XBANA,XKURY,XUMAL,XFINK;" },
    { val: [], name: "LILAK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XKTUM,XKTMT,XUSIN,XSINS,XKAGT,XSTKR;" },
    { val: [], name: "LINK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "LKMNH", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUHIZ,XSANK;" },
    { val: [], name: "LMKDC", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUSIN,XSINS,XTAST;" },
    { val: [], name: "LOGO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XSRDK,XKTMT,XKURY,XUSRD,XUGRA,XUTEK,XTKJS,XBLSM,XSKOC;" },
    { val: [], name: "LRSHO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSKON;" },
    { val: [], name: "LUKSK", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XSINS,XTEKS,XSKAY;" },
    { val: [], name: "LYDHO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "LYDYE", ix: "XUTUM,XTUMY,XUHIZ,XELKT;" },
    { val: [], name: "MAALT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUHIZ,XTRZM,XSANT;" },
    { val: [], name: "MACKO", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XTMTU,XUHIZ,XSIST;" },
    { val: [], name: "MAGEN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSRD,XUGRA,XUHIZ,XELKT,XSANK;" },
    { val: [], name: "MAKIM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XMESY,XSANK;" },
    { val: [], name: "MAKTK", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMESY,XSKOC;" },
    { val: [], name: "MANAS", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSANK;" },
    { val: [], name: "MARBL", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSINS,XTAST;" },
    { val: [], name: "MARKA", ix: "XUTUM,XTUMY,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "MARTI", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSANT;" },
    { val: [], name: "MAVI", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XUSRD,XSD25,XTM25S,XUHIZ,XTCRT;" },
    { val: [], name: "MEDTR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUSIN,XSINS,XKMYA,XSIZM;" },
    { val: [], name: "MEGMT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XSINS,XMANA,XSKAY;" },
    { val: [], name: "MEKAG", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSINS,XMESY,XSANK;" },
    { val: [], name: "MEPET", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTCRT;" },
    { val: [], name: "MERCN", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUSIN,XSINS,XKMYA,XSDNZ;" },
    { val: [], name: "MERIT", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XUHIZ,XTRZM,XSIST;" },
    { val: [], name: "MERKO", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XGIDA,XSBUR;" },
    { val: [], name: "METRO", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "METUR", ix: "XU500,XUTUM,XTUMY,XBANA,XUGRA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "MGROS", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XSD25,XUHIZ,XTCRT;" },
    { val: [], name: "MHRGY", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XUGRA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "MIATK", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUTEK,XTKJS,XBLSM,XSANK;" },
    { val: [], name: "MNDRS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XTEKS,XSDNZ;" },
    { val: [], name: "MNDTR", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XKAGT,XSIZM;" },
    { val: [], name: "MOBTL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "MOGAN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSANK;" },
    { val: [], name: "MPARK", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XSRDK,XUSRD,XUHIZ,XSIST;" },
    { val: [], name: "MRGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "MRSHL", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XKMYA,XSKOC;" },
    { val: [], name: "MSGYO", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUGRA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "MTRKS", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "MTRYO", ix: "XYORT;" },
    { val: [], name: "MZHLD", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIZM;" },
    { val: [], name: "NATEN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSRD,XUGRA,XUHIZ,XELKT,XSANK;" },
    { val: [], name: "NETAS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "NIBAS", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XTAST;" },
    { val: [], name: "NTGAZ", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUHIZ,XELKT,XSIST;" },
    { val: [], name: "NTHOL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "NUGYO", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "NUHCM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XSRDK,XKTMT,XUSRD,XUSIN,XSINS,XTAST,XSKOC;" },
    { val: [], name: "OBAMS", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "OBASE", ix: "XU500,XUTUM,XTUMY,XBANA,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "ODAS", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUGRA,XUHIZ,XELKT;" },
    { val: [], name: "ODINE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "OFSYM", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSINS,XGIDA,XSANK;" },
    { val: [], name: "ONCSM", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKOBI,XKTUM,XUSIN,XSINS,XKMYA,XSANK;" },
    { val: [], name: "ONRYT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUTEK,XTKJS,XSANK;" },
    { val: [], name: "ORCAY", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "ORGE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUGRA,XUHIZ,XINSA,XSIST;" },
    { val: [], name: "ORMA", ix: "XUTUM,XTUMY;" },
    { val: [], name: "OSMEN", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XUMAL,XAKUR;" },
    { val: [], name: "OSTIM", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSANK;" },
    { val: [], name: "OTKAR", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XSRDK,XKURY,XUSRD,XUSIN,XSINS,XMESY;" },
    { val: [], name: "OYAKC", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUSIN,XSINS,XTAST,XSANK;" },
    { val: [], name: "OYAYO", ix: "XYORT;" },
    { val: [], name: "OYLUM", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XGIDA,XSKAY;" },
    { val: [], name: "OYYAT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XAKUR;" },
    { val: [], name: "OZATD", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XSINS,XMESY;" },
    { val: [], name: "OZGYO", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "OZKGY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "OZRDN", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XKMYA,XSKOC;" },
    { val: [], name: "OZSUB", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSINS,XSIZM;" },
    { val: [], name: "OZYSR", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSINS,XMANA,XSIST;" },
    { val: [], name: "PAGYO", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "PAMEL", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XELKT;" },
    { val: [], name: "PAPIL", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XUTEK,XTKJS,XBLSM,XSANK;" },
    { val: [], name: "PARSN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XMESY,XSIST;" },
    { val: [], name: "PASEU", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XULAS,XSANK;" },
    { val: [], name: "PATEK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "PCILT", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XUHIZ,XSIST;" },
    { val: [], name: "PEHOL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSANK;" },
    { val: [], name: "PEKGY", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "PENGD", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XGIDA,XSBUR;" },
    { val: [], name: "PENTA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "PETKM", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XSD25,XUSIN,XSINS,XKMYA,XSIZM;" },
    { val: [], name: "PETUN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XKURY,XUSIN,XSINS,XGIDA,XSIZM;" },
    { val: [], name: "PGSUS", ix: "XU100,XU050,XU030,X10XB,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XSD25,XUHIZ,XULAS,XSIST;" },
    { val: [], name: "PINSU", ix: "XU500,XUTUM,XTUMY,XBANA,XKURY,XUSIN,XSINS,XGIDA,XSIZM;" },
    { val: [], name: "PKART", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "PKENT", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSANT;" },
    { val: [], name: "PLTUR", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUHIZ,XSIST;" },
    { val: [], name: "PNLSN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XSINS,XMANA,XSANK;" },
    { val: [], name: "PNSUT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XKURY,XUSIN,XSINS,XGIDA,XSIZM;" },
    { val: [], name: "POLHO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XSRDK,XKTMT,XUSRD,XUMAL,XHOLD,XSKOC;" },
    { val: [], name: "POLTK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKOBI,XUSIN,XSINS,XKMYA,XSIST;" },
    { val: [], name: "PRDGS", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD;" },
    { val: [], name: "PRKAB", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XKURY,XUSIN,XSINS,XMESY,XSBUR;" },
    { val: [], name: "PRKME", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKURY,XUGRA,XUSIN,XSINS,XMADN;" },
    { val: [], name: "PRZMA", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XSINS,XKAGT,XSIST;" },
    { val: [], name: "PSDTC", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XTCRT,XSIZM;" },
    { val: [], name: "PSGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUGRA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "QNBFB", ix: "XUTUM,XTUMY;" },
    { val: [], name: "QNBFL", ix: "XUTUM,XTUMY;" },
    { val: [], name: "QUAGR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XK050,XSRDK,XKURY,XUSRD,XUSIN,XSINS,XTAST,XSAYD;" },
    { val: [], name: "RALYH", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUGRA,XUMAL,XHOLD,XSANK;" },
    { val: [], name: "RAYSG", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XSGRT;" },
    { val: [], name: "REEDR", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XHARZ,XUTEK,XTKJS,XBLSM;" },
    { val: [], name: "RGYAS", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XUHIZ,XSANK;" },
    { val: [], name: "RNPOL", ix: "XU500,XUTUM,XTUMY,XBANA,XUGRA,XUSIN,XSINS,XKMYA,XSKAY;" },
    { val: [], name: "RODRG", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XTEKS,XSIST;" },
    { val: [], name: "RTALB", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XSINS,XKMYA,XSKOC;" },
    { val: [], name: "RUBNS", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUGRA,XUSIN,XSINS,XTEKS;" },
    { val: [], name: "RYGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "RYSAS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUHIZ,XULAS,XSIST;" },
    { val: [], name: "SAFKR", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMESY,XSIZM;" },
    { val: [], name: "SAHOL", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XTM25S,XUGRA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "SAMAT", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XKAGT,XSANK;" },
    { val: [], name: "SANEL", ix: "XUTUM,XTUMY,XKTUM,XUHIZ,XINSA,XSIST;" },
    { val: [], name: "SANFM", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XKMYA,XSTKR;" },
    { val: [], name: "SANKO", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUHIZ,XTCRT;" },
    { val: [], name: "SARKY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUGRA,XUSIN,XSINS,XMANA,XSKOC;" },
    { val: [], name: "SASA", ix: "XU100,XU050,XU030,X10XB,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XUSIN,XSINS,XKMYA,XSADA;" },
    { val: [], name: "SAYAS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSIN,XSINS,XMESY,XSIZM;" },
    { val: [], name: "SDTTR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XUTEK,XTKJS,XSANK;" },
    { val: [], name: "SEGMN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XSINS,XGIDA,XSANK;" },
    { val: [], name: "SEGYO", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "SEKFK", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XKURY,XUMAL,XFINK;" },
    { val: [], name: "SEKUR", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XKMYA;" },
    { val: [], name: "SELEC", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUHIZ,XTCRT,XSIST;" },
    { val: [], name: "SELGD", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSINS,XGIDA,XSAYD;" },
    { val: [], name: "SELVA", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XKURY,XUSIN,XSINS,XGIDA,XSKON;" },
    { val: [], name: "SEYKM", ix: "XU500,XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XSINS,XKMYA,XSMNS;" },
    { val: [], name: "SILVR", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMESY;" },
    { val: [], name: "SISE", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XTM25S,XUGRA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "SKBNK", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XBANK;" },
    { val: [], name: "SKTAS", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XTEKS,XSAYD;" },
    { val: [], name: "SKYLP", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XSIST;" },
    { val: [], name: "SKYMD", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XUMAL,XAKUR;" },
    { val: [], name: "SMART", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "SMRTG", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUHIZ,XELKT,XSKOC;" },
    { val: [], name: "SNGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "SNICA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XSINS,XMESY,XSMNS;" },
    { val: [], name: "SNPAM", ix: "XUTUM,XTUMY;" },
    { val: [], name: "SODSN", ix: "XUTUM,XTUMY;" },
    { val: [], name: "SOKE", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XSINS,XGIDA,XSAYD;" },
    { val: [], name: "SOKM", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUHIZ,XTCRT;" },
    { val: [], name: "SONME", ix: "XUTUM,XTUMY,XKTUM,XUHIZ,XSBUR;" },
    { val: [], name: "SRVGY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "SUMAS", ix: "XUTUM,XTUMY;" },
    { val: [], name: "SUNTK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XSRDK,XKTMT,XKURY,XUSRD,XUSIN,XSINS,XTEKS,XSIZM;" },
    { val: [], name: "SURGY", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "SUWEN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUHIZ,XTCRT;" },
    { val: [], name: "TABGD", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XHARZ,XKTUM,XK100,XK050,XK030,XUHIZ,XTRZM,XSIST;" },
    { val: [], name: "TARKM", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XSINS,XKMYA,XSMNS;" },
    { val: [], name: "TATEN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSBAL;" },
    { val: [], name: "TATGD", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUSIN,XSINS,XGIDA,XSBUR;" },
    { val: [], name: "TAVHL", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "TBORG", ix: "XUTUM,XTUMY,XBANA,XUSIN,XSINS,XGIDA,XSIZM;" },
    { val: [], name: "TCELL", ix: "XU100,XU050,XU030,X10XB,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XTM25S,XUGRA,XUHIZ,XILTM,XSIST;" },
    { val: [], name: "TCKRC", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XSINS,XMANA,XSBUR;" },
    { val: [], name: "TDGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "TEKTU", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSANT;" },
    { val: [], name: "TERA", ix: "XU500,XUTUM,XTUMY,XBANA,XHARZ,XUMAL,XAKUR;" },
    { val: [], name: "TEZOL", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XSINS,XKAGT,XSIZM;" },
    { val: [], name: "TGSAS", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XUHIZ,XTCRT,XSIST;" },
    { val: [], name: "THYAO", ix: "XU100,XU050,XU030,X10XB,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XUGRA,XUHIZ,XULAS,XSIST;" },
    { val: [], name: "TKFEN", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XUGRA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "TKNSA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUHIZ,XTCRT;" },
    { val: [], name: "TLMAN", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XULAS;" },
    { val: [], name: "TMPOL", ix: "XU500,XUTUM,XTUMY,XBANA,XUSIN,XSINS,XKMYA,XSKAY;" },
    { val: [], name: "TMSN", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XSINS,XMESY,XSKON;" },
    { val: [], name: "TNZTP", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XSIZM;" },
    { val: [], name: "TOASO", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XTM25S,XUSIN,XSINS,XMESY,XSBUR;" },
    { val: [], name: "TRCAS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKURY,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "TRGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "TRILC", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XKMYA,XSANK;" },
    { val: [], name: "TSGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "TSKB", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XBANK;" },
    { val: [], name: "TSPOR", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUHIZ,XSPOR;" },
    { val: [], name: "TTKOM", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XUHIZ,XILTM,XSANK;" },
    { val: [], name: "TTRAK", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XTM25S,XUSIN,XSINS,XMESY,XSANK;" },
    { val: [], name: "TUCLK", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMANA,XSIST;" },
    { val: [], name: "TUKAS", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XSINS,XGIDA,XSIZM;" },
    { val: [], name: "TUPRS", ix: "XU100,XU050,XU030,X10XB,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XSD25,XUSIN,XSINS,XKMYA,XSKOC;" },
    { val: [], name: "TUREX", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUHIZ,XULAS,XSIST;" },
    { val: [], name: "TURGG", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XINSA,XSIST;" },
    { val: [], name: "TURSG", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XKURY,XUMAL,XSGRT;" },
    { val: [], name: "UFUK", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "ULAS", ix: "XUTUM,XTUMY,XUHIZ,XTRZM,XSANK;" },
    { val: [], name: "ULKER", ix: "XU100,XU050,XU030,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSRD,XSD25,XUSIN,XSINS,XGIDA,XSANK;" },
    { val: [], name: "ULUFA", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XUMAL,XFINK;" },
    { val: [], name: "ULUSE", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMESY,XSANK;" },
    { val: [], name: "ULUUN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "UMPAS", ix: "XUTUM,XTUMY;" },
    { val: [], name: "UNLU", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "USAK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XTAST;" },
    { val: [], name: "VAKBN", ix: "XU100,XU050,XLBNK,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XBANK;" },
    { val: [], name: "VAKFN", ix: "XU500,XUTUM,XTUMY,XYLDZ,XUMAL,XFINK;" },
    { val: [], name: "VAKKO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUHIZ,XTCRT;" },
    { val: [], name: "VANGD", ix: "XUTUM,XTUMY,XKOBI,XKTUM,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "VBTYZ", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUTEK,XTKJS,XBLSM,XSIST;" },
    { val: [], name: "VERTU", ix: "XU500,XUTUM,XTUMY,XBANA,XTMTU,XUGRA,XUMAL,XHOLD;" },
    { val: [], name: "VERUS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUGRA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "VESBE", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XSRDK,XKTMT,XUSRD,XTM25S,XUSIN,XSINS,XMESY,XSMNS;" },
    { val: [], name: "VESTL", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XUSIN,XSINS,XMESY,XSMNS;" },
    { val: [], name: "VKFYO", ix: "XYORT;" },
    { val: [], name: "VKGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKURY,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "VKING", ix: "XUTUM,XTUMY,XBANA,XUSIN,XSINS,XKAGT,XSIZM;" },
    { val: [], name: "VRGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "YAPRK", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKOBI,XTMTU,XUSIN,XSINS,XSBAL;" },
    { val: [], name: "YATAS", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XSINS,XTEKS,XSKAY;" },
    { val: [], name: "YAYLA", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSANK;" },
    { val: [], name: "YBTAS", ix: "XUTUM,XTUMY;" },
    { val: [], name: "YEOTK", ix: "XU100,XU500,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUHIZ,XSIST;" },
    { val: [], name: "YESIL", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST;" },
    { val: [], name: "YGGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "YGYO", ix: "XU500,XUTUM,XTUMY,XBANA,XUMAL,XGMYO,XGYOS;" },
    { val: [], name: "YIGIT", ix: "XU500,XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XSINS,XMESY,XSANK;" },
    { val: [], name: "YKBNK", ix: "XU100,XU050,XU030,XLBNK,XU500,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XTM25S,XUMAL,XBANK;" },
    { val: [], name: "YKSLN", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XSINS,XMANA,XSIST;" },
    { val: [], name: "YONGA", ix: "XUTUM,XTUMY;" },
    { val: [], name: "YUNSA", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XKURY,XUSIN,XSINS,XTEKS,XSTKR;" },
    { val: [], name: "YYAPI", ix: "XU500,XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSIST;" },
    { val: [], name: "YYLGD", ix: "XU500,XUTUM,XTUMY,XYLDZ,XKURY,XUGRA,XUSIN,XSINS,XGIDA;" },
    { val: [], name: "ZEDUR", ix: "XU500,XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XELKT,XSANK;" },
    { val: [], name: "ZOREN", ix: "XU100,XU050,XU500,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XUSRD,XSD25,XUHIZ,XELKT,XSBUR;" },
    { val: [], name: "ZRGYO", ix: "XU500,XUTUM,XTUMY,XYLDZ,XTMTU,XUMAL,XGMYO,XGYOS;" },

];


ImkbEndeksSenetler.forEach(element => {
    let list = element.ix.split(',');
    element.val = list.filter((x) => {
        return SentimentEndeksler.some(s => s.value === x);
    });
});
export const ImportImkbEndeksSenetlerMap = ImkbEndeksSenetler.reduce((map, obj) => {
    map[obj.name] = { ...obj };
    return map;
}, {});



export default ImkbEndeksSenetler;