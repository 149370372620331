import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import klineInterval from "./params";

const DATA_COUNT = 250;
const COLLECTION_NAME = "NS_SIGNALS_70";

interface IXUData {
  signal70: { Basket: string[]; Outgoing: []; Incoming: []; Index: number };
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  signal70: {} as any,
  requested: false,
  loaded: false,
};

export function loadSignal70(firebase: any, period: number) {
  if (period < 0 || ![0, 10, 30, 99, 24].includes(period)) {
    console.log("Invalid period for signal 30. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Signal70.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.SIGNAL_70,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    let ix = 0;
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}_P${period}`)
      .where("reverse", "==", 0)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        let signal70Data: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          signal70Data.push({
            key: ix++,
            name: "signal",
            Basket: doc.data().basket ?? [],
            Outgoing: doc.data().outgoing ?? [],
            Index: doc.data().index,
            Incoming: doc.data().incoming ?? [],
            Time: doc.data().time ?? 0,
            Symbols: doc.data().symbols ?? [],
            TakeReserve: doc.data().takeReserve,
            Date: moment(doc.data().dateTime.toDate()).format("DD.MM.YY HH:mm"),
            Day: moment(doc.data().dateTime.toDate()).format("DD.MM.YY"),
            EndOfDay: doc.data().time >= 1805
          });
        });
        dispatch({
          type: SENTIMENT_ACTION.SIGNAL_70,
          payload: {
            signal70: signal70Data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Signal70(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.SIGNAL_70:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const signal70Selector = (state: any) => state.Signal70;
